import type { CSSProperties, FC, KeyboardEvent, ReactNode } from 'react'
import React, { useEffect, useState } from 'react'
import { Strings } from '@goatlab/js-utils'
import { castNameToString, useParseInputProps } from '@goatlab/react-zod-form'
import { Controller } from 'react-hook-form'
import type { BaseField } from '../types/baseField'
import { Tooltip } from '../Tooltip'

// KeyCode constants
const Backspace = 8
const LeftArrow = 37
const RightArrow = 39
const Delete = 46
const Spacebar = 32

const isStyleObject = (input: any): input is CSSProperties => {
  if (typeof input !== 'object' || input === null) {
    return false
  }

  return true
}

type SingleOtpInputProps = BaseField<string> & {
  tooltip?: ReactNode
  separator: React.ReactNode
  isLastChild?: boolean
  inputStyle: CSSProperties
  focus?: boolean
  isDisabled?: boolean
  hasErrored?: boolean
  errorStyle?: CSSProperties
  focusStyle?: CSSProperties
  disabledStyle?: CSSProperties
  shouldAutoFocus?: boolean
  isInputNum?: boolean
  numInputs?: number
  index?: number
  isInputSecure?: boolean
  containerStyle?: CSSProperties | string
  onValueChange?: (value: string) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  'data-cy'?: string
  'data-testid'?: string
  onKeyDown?: (event: KeyboardEvent) => void
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void
}

const SingleOtpInput: FC<SingleOtpInputProps> = (props) => {
  const input = React.createRef<HTMLInputElement>()

  const {
    placeholder,
    separator,
    isLastChild,
    inputStyle,
    focus,
    isDisabled,
    hasErrored,
    errorStyle,
    focusStyle,
    disabledStyle,
    shouldAutoFocus,
    isInputNum,
    index,
    defaultValue,
    className,
    isInputSecure,
    name,
    ...rest
  } = props

  const nameString = castNameToString(name)

  const getType = () => {
    if (isInputSecure) {
      return 'password'
    }

    if (isInputNum) {
      return 'tel'
    }

    return 'text'
  }

  const getClasses = (...classes: (string | boolean | CSSProperties)[]) => {
    // eslint-disable-next-line  @typescript-eslint/no-base-to-string
    return classes.filter((c) => !isStyleObject(c) && c !== false).join(' ')
  }

  useEffect(() => {
    const { current: inputEl } = input

    if (inputEl && focus && shouldAutoFocus) {
      inputEl.focus()
    }
  }, [focus])

  return (
    <div
      className={className}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <input
        name={nameString}
        aria-label={`${index === 0 ? 'Please enter verification code. ' : ''}${
          isInputNum ? 'Digit' : 'Character'
        } ${(index ?? 0) + 1}`}
        autoComplete="off"
        style={{
          width: '1em',
          height: '2.5em',
          textAlign: 'center',
          backgroundColor: 'white',
          fontSize: '16px',
          color: 'black',
          ...(isStyleObject(inputStyle) && inputStyle),
          ...(focus && isStyleObject(focusStyle) && focusStyle),
          ...(isDisabled && isStyleObject(disabledStyle) && disabledStyle),
          ...(hasErrored && isStyleObject(errorStyle) && errorStyle),
        }}
        placeholder={placeholder}
        className={getClasses(
          inputStyle,
          (focus && focusStyle) ?? {},
          (isDisabled && disabledStyle) ?? {},
          (hasErrored && errorStyle) ?? {},
          'rounded-md border border-slate-400',
        )}
        type={getType()}
        maxLength={1}
        ref={input}
        disabled={isDisabled}
        value={defaultValue ?? ''}
        {...rest}
      />
      {!isLastChild && separator}
    </div>
  )
}

const defaultProps: Partial<SingleOtpInputProps> = {
  numInputs: 4,
  isDisabled: false,
  shouldAutoFocus: false,
  isInputSecure: false,
}

export const OtpInput: FC<SingleOtpInputProps> = (props) => {
  const {
    containerStyle,
    formHook: hook,
    label,
    tooltip,
    required,
  } = {
    ...defaultProps,
    ...props,
  }

  const { name, formHook, errorMessage } = useParseInputProps({
    name: props.name,
    formHook: hook,
  })

  const [activeInput, setActiveInput] = useState<number>(0)

  const getOtpValue = () => {
    return formHook.getValues(name)
      ? formHook.getValues(name).toString().split('')
      : []
  }

  const getPlaceholderValue = () => {
    const { placeholder, numInputs } = props

    if (typeof placeholder === 'string') {
      if (placeholder.length === numInputs) {
        return placeholder
      }

      if (placeholder.length > 0) {
        console.error(
          'Length of the placeholder should be equal to the number of inputs.',
        )
        return undefined
      }

      return undefined
    }

    return undefined
  }

  // Helper to return OTP from input
  const handleOtpChange = (otp: string[]) => {
    const otpValue = otp.join('')
    props?.onValueChange?.(otpValue)

    formHook.setValue(name, otpValue, {
      shouldValidate: true,
    })
  }

  const isInputValueValid = (value: string) => {
    const isTypeValid = props.isInputNum
      ? !isNaN(parseInt(value, 10))
      : typeof value === 'string'

    return isTypeValid && value.trim().length === 1
  }

  // Focus on input by index
  const focusInput = (input: number) => {
    const { numInputs } = props

    const activeInputNumber = Math.max(Math.min((numInputs ?? 1) - 1, input), 0)
    setActiveInput(activeInputNumber)
  }

  // Focus on next input
  const focusNextInput = () => {
    focusInput(activeInput + 1)
  }

  // Focus on previous input
  const focusPrevInput = () => {
    focusInput(activeInput - 1)
  }

  // Change OTP value at focused input
  const changeCodeAtFocus = (value: string) => {
    const otp = getOtpValue()
    otp[activeInput] = value
    handleOtpChange(otp)
  }

  // Handle pasted OTP
  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()

    const { numInputs, isDisabled } = props

    if (isDisabled) {
      return
    }

    const otp = getOtpValue()
    let nextActiveInput = activeInput

    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData = e.clipboardData
      .getData('text/plain')
      .slice(0, (numInputs ?? 0) - activeInput)
      .split('')

    // Paste data from focused input onwards
    for (let pos = 0; pos < (numInputs ?? 0); ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift() ?? ''
        nextActiveInput++
      }
    }

    setActiveInput(nextActiveInput)

    // TODO: this two need to happen after setActiveInput
    // is updated
    focusInput(nextActiveInput)
    handleOtpChange(otp)
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    if (isInputValueValid(value)) {
      changeCodeAtFocus(value)
    }
  }

  // Handle cases of backspace, delete, left arrow, right arrow, space
  const handleOnKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === Backspace || e.key === 'Backspace') {
      e.preventDefault()
      changeCodeAtFocus('')
      focusPrevInput()
    } else if (e.keyCode === Delete || e.key === 'Delete') {
      e.preventDefault()
      changeCodeAtFocus('')
    } else if (e.keyCode === LeftArrow || e.key === 'ArrowLeft') {
      e.preventDefault()
      focusPrevInput()
    } else if (e.keyCode === RightArrow || e.key === 'ArrowRight') {
      e.preventDefault()
      focusNextInput()
    } else if (
      e.keyCode === Spacebar ||
      e.key === ' ' ||
      e.key === 'Spacebar' ||
      e.key === 'Space'
    ) {
      e.preventDefault()
    }
  }

  // The content may not have changed, but some input took place hence change the focus
  const handleOnInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isInputValueValid(e.target.value)) {
      focusNextInput()
      return
    }
    // This is a workaround for dealing with keyCode "229 Unidentified" on Android.

    if (!props.isInputNum) {
      const { nativeEvent } = e as any

      if (
        nativeEvent.data === null &&
        nativeEvent.inputType === 'deleteContentBackward'
      ) {
        e.preventDefault()
        changeCodeAtFocus('')
        focusPrevInput()
      }
    }
  }

  const renderInputs = () => {
    const {
      numInputs,
      inputStyle,
      focusStyle,
      separator,
      isDisabled,
      disabledStyle,
      hasErrored,
      errorStyle,
      isInputNum,
      isInputSecure,
      className,
    } = props

    const inputs = []
    const otp = getOtpValue()
    const placeholder = getPlaceholderValue()
    const dataCy = props['data-cy']
    const dataTestId = props['data-testid']

    for (let i = 0; i < (numInputs ?? 0); i++) {
      inputs.push(
        <SingleOtpInput
          name={`${name}-${i}`}
          placeholder={placeholder?.[i]}
          key={i}
          index={i}
          focus={activeInput === i}
          defaultValue={otp?.[i]}
          onChange={handleOnChange as any}
          onKeyDown={handleOnKeyDown}
          onInput={handleOnInput}
          onPaste={handleOnPaste}
          onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
            setActiveInput(i)
            e.target.select()
          }}
          onBlur={() => {
            // SetActiveInput(activeInput - 1)
          }}
          separator={separator}
          inputStyle={inputStyle}
          focusStyle={focusStyle}
          isLastChild={i === (numInputs ?? 0) - 1}
          isDisabled={isDisabled}
          disabledStyle={disabledStyle}
          hasErrored={hasErrored}
          errorStyle={errorStyle}
          shouldAutoFocus={activeInput === i}
          isInputNum={isInputNum}
          isInputSecure={isInputSecure}
          className={className}
          data-cy={dataCy && `${dataCy}-${i}`}
          data-testid={dataTestId && `${dataTestId}-${i}`}
        />,
      )
    }

    return inputs
  }

  return (
    <div className="flex flex-col pb-4">
      <label
        className="mb-3 block cursor-pointer text-sm font-semibold leading-none text-gray-600"
        htmlFor={name}
      >
        <span className="mr-2">{Strings.capitalize(label ?? '')}</span>

        {tooltip && <Tooltip tip={tooltip} className="mr-2" />}
        {required && <span className="text-rose-500"> *</span>}
      </label>

      <Controller
        control={formHook.control}
        name={name}
        defaultValue={props.defaultValue}
        render={() => {
          return (
            <div
              style={{
                display: 'flex',
                ...(isStyleObject(containerStyle) && containerStyle),
              }}
              className={isStyleObject(containerStyle) ? '' : containerStyle}
            >
              {renderInputs()}
            </div>
          )
        }}
      />

      {errorMessage && (
        <div className="mt-2 pl-1 text-xs text-red-600">
          {String(errorMessage)}
        </div>
      )}
    </div>
  )
}
