import React, { useEffect, useState } from 'react'
import { Button } from '@components/ui/buttons/Button2'
import { Input } from '@components/ui/forms/fields/Input'
import { OtpInput } from '@components/ui/forms/fields/OtpInput'
import { useUI } from '@contexts/ui/ui.context'
import { useToast } from '@goatlab/react-ui'
import { Form, useZodFormHook } from '@goatlab/react-zod-form'
import { backendHook } from '@src/api/trpc'
import { useIs } from '@src/hooks/useIs'
import { firebaseAuthService } from '@src/services/firebase/firebaseAuth.service'
import { sodiumConfig } from '@src/sodiumConfig'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'
import clsx from 'clsx'
import { useAuthState } from 'react-firebase-hooks/auth'
import { z } from 'zod'
import { AppleLoginButton } from './components/AppleLoginButton'
import { GoogleLoginButton } from './components/GoogleLoginButton'
import { LinkedInLoginButton } from './components/LinkedinLoginButton'
import { useAuth } from './store/auth.store'

const loginSchema = z.object({
  email: z.string().email(),
  otp: z.string().optional(),
})

type LoginSchemaType = z.infer<typeof loginSchema>

const defaultValues: LoginSchemaType = {
  email: '',
  otp: '',
}

interface LoginFormProps {
  transparentLogoBackground?: boolean
}

export const LoginForm: React.FC<LoginFormProps> = ({
  transparentLogoBackground,
}) => {
  const [user, loading] = useAuthState(firebaseAuthService.getAuth())
  const { toast } = useToast()
  const [otpRequested, setOtpRequested] = useState<boolean>(false)
  const [lastRequestTime, setLastRequestTime] = useState<number | null>(null)
  const [timeRemaining, setTimeRemaining] = useState<number>(0)

  const sendOtp = backendHook.backend.user.sendOtp.useMutation()
  const logInWithOtp = backendHook.backend.user.logInWithOtp.useMutation()

  const formHook = useZodFormHook({ schema: loginSchema, defaultValues })

  const { t } = useTypedTranslation('common')
  const { closeModal } = useUI()
  const { user: authUser } = useAuth()
  const { isDesktop, isLargeDesktop } = useIs()

  const [isProfileSet, setIsProfileSet] = useState(false)

  useEffect(() => {
    if (authUser?.account) {
      setIsProfileSet(true)
    }
  }, [authUser?.account])

  useEffect(() => {
    if (user?.email) {
      closeModal()
    }
  }, [user, loading])

  const login = async () => {
    const { email, otp } = formHook.getValues()
    if (!otp || !email) {
      return
    }

    const result = await logInWithOtp.mutateAsync({
      email,
      otp,
    })

    await firebaseAuthService.signInWithToken(result.authToken)

    // This should be removed, but we need to find a better
    // way to wait for the /me call
    while (!isProfileSet) {
      // Sleep for a small amount of time to prevent busy-waiting
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => setTimeout(resolve, 100))
    }
  }

  const onSubmit = async () => {
    const { email } = formHook.getValues()
    if (email && !timeRemaining) {
      await sendOtp.mutateAsync({ email })
      setLastRequestTime(Date.now())
      setOtpRequested(true)
      toast({
        title: 'OTP sent',
      })
      return
    }

    await login()
  }

  const otps = formHook.useWatch(formHook.path.otp)

  useEffect(() => {
    if (otps?.length === 6) {
      void login()
    }
  }, [otps])

  useEffect(() => {
    if (lastRequestTime) {
      const intervalId = setInterval(() => {
        const elapsed = Math.floor((Date.now() - lastRequestTime) / 1000)
        const remaining = Math.max(0, 30 - elapsed)
        setTimeRemaining(remaining)
        if (remaining === 0) {
          clearInterval(intervalId)
        }
      }, 1000)
      return () => clearInterval(intervalId)
    }
    return
  }, [lastRequestTime])

  const isButtonDisabled =
    loading || sendOtp.isPending || logInWithOtp.isPending

  return (
    <div
      className={clsx(
        `flex h-[100dvh] w-[100dvw] flex-col pt-6 xl:h-[70dvh] xl:max-w-sm`,
        {
          'rounded-t-lg border-1 border-gray-300':
            (isDesktop || isLargeDesktop) && transparentLogoBackground,
        },
      )}
      style={
        transparentLogoBackground
          ? {
              background: 'transparent',
            }
          : {
              background: `radial-gradient(circle, ${sodiumConfig.login.fromRadialColor}, ${sodiumConfig.login.toRadialColor})`,
            }
      }
    >
      <div className="flex items-center justify-center pb-14">
        <div>{sodiumConfig.login.loginImage}</div>
      </div>

      <div
        className="flex flex-1 flex-col bg-white px-8 pt-6"
        style={{ borderTopLeftRadius: 30, borderTopRightRadius: 30 }}
      >
        <Form formHook={formHook} onSuccess={onSubmit}>
          <div className="flex flex-col space-y-3.5">
            <Input
              name={formHook.path.email}
              label={t('email-label', { ns: 'login' })}
              placeholder={t('email-placeholder', { ns: 'login' })}
              formHook={formHook}
            />
            <div
              className={clsx('h-64 rounded bg-white py-3 text-center', {
                hidden: !otpRequested,
              })}
            >
              <h1 className="text-xl font-bold">
                {t('login-code-verification')}
              </h1>
              <div className="mt-4 flex flex-col">
                <span className="text-sm">
                  {t('login-code-verification-helper')}
                </span>
                <span className="text-xs font-bold">
                  {formHook.getValues().email}
                </span>
              </div>

              <OtpInput
                name={formHook.path.otp}
                formHook={formHook}
                numInputs={6}
                isInputNum={true}
                containerStyle={
                  'flex flex-row justify-center text-center px-2 mt-5'
                }
                inputStyle={{
                  width: '35px',
                  outline: 'none',
                }}
                separator={<span className="mx-2"> - </span>}
              />

              <div className="mt-5 flex justify-center text-center">
                <a
                  className={clsx(
                    'flex items-center text-blue-700 hover:text-blue-900',
                    {
                      'cursor-pointer': !timeRemaining,
                      disabled: timeRemaining,
                    },
                  )}
                  onClick={async (e) => {
                    e.preventDefault()
                    if (timeRemaining > 0) {
                      return
                    }
                    await onSubmit()
                  }}
                >
                  <span className="font-bold">
                    Resend OTP
                    {timeRemaining > 0 && ` in ${timeRemaining} seconds`}
                  </span>
                  <i className="bx bx-caret-right ml-1"></i>
                </a>
              </div>
            </div>

            <div className="relative">
              {otpRequested ? (
                <Button
                  loading={isButtonDisabled}
                  disabled={isButtonDisabled}
                  className={'w-full'}
                >
                  {t('login-with-otp')}
                </Button>
              ) : (
                <Button
                  type="submit"
                  loading={isButtonDisabled}
                  disabled={isButtonDisabled}
                  className={'w-full'}
                >
                  {t('login-request-otp')}
                </Button>
              )}
            </div>
          </div>
        </Form>

        <div className="relative mb-3.5 mt-6 flex flex-col items-center justify-center text-sm text-heading">
          <span className="absolute top-3 px-2">
            {t('connect-with', { ns: 'login' })}
          </span>
        </div>

        <div className="relative mb-3.5 mt-6 flex flex-row items-center justify-center gap-6 text-sm text-heading">
          <GoogleLoginButton isLoading={loading} />
          <LinkedInLoginButton isLoading={loading} />
          <AppleLoginButton isLoading={loading} />
        </div>

        <div className="flex flex-col items-center justify-end pb-6 text-center">
          <span className="mb-12 mt-12 w-[280px] px-2 text-center text-xs text-gray-500">
            {t('login-terms-1')}{' '}
            <a
              className="text-blue-500 underline"
              target="_blank"
              href={sodiumConfig.legal.privacyPolicyUrl}
              rel="noopener"
            >
              {t('login-privacy')}{' '}
            </a>{' '}
            {t('login-terms-2')}{' '}
            <a
              className="text-blue-500 underline"
              target="_blank"
              href={sodiumConfig.legal.termsOfService}
              rel="noopener"
            >
              {t('login-terms')}{' '}
            </a>
            {t('login-current-terms')}
          </span>
        </div>
      </div>
    </div>
  )
}
